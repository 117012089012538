:root {
  --yellow: #f9a602;
  --green: #2d67b2;
  --teal: #5490CC;
  // Dark Blue
  --blue: #2d67b2;
  --gray: #9b99a9;
  --dark-gray: #5f5d70;
  --primary: var(--blue);

  --primary-gradient: linear-gradient(45deg, #1B3664, #5490CC);
  --secondary-gradient: linear-gradient(#1B3664, #5490CC);

  --font-family-sans-serif: Avenir, sans-serif;
  --body-font-size: 1rem; // 16px
  --font-size-h-six: 0.9375rem; // 15px
  --font-size-h-five: 0.75rem; // 12px
  --font-size-h-four: 0.875rem; // 14px
  --font-size-h-three: 0.9375rem; // 14px
  --font-size-h-two: 1rem; // 16px
  --font-size-h-one: 1.375rem; // 22px

  // Colors
  --color-grocery: rgb(109, 124, 206);
  --color-grocery-selected: #5D69B1;
  --color-fitness: rgb(71, 233, 190);
  --color-fitness-selected: #1B998B;
  --color-theater: rgb(145, 64, 165);
  --color-theater-selected: #7f3092;
  --color-restaurant: rgb(245, 132, 124);
  --color-restaurant-selected: #ED645A;
  --color-perks-program: rgb(88, 129, 218);
  --color-perks-program-selected: #3B61B6;

  --color-events: #E0CA3C;
}
h1 {
  font-size: var(--font-size-h-one);
}
h2 {
  font-size: var(--font-size-h-two);
}
h3 {
  font-size: var(--font-size-h-three);
}
h4 {
  font-size: var(--font-size-h-four);
}
h5 {
  font-size: var(--font-size-h-five);
}
h6 {
  font-size: var(--font-size-h-six);
}
body {
  // overscroll-behavior-y: contain;
}
html {
  height: 100vh;
}
body,
#root,
.App {
  height: inherit;
}
a,
button {
  color: var(--blue);
  font-weight: bold;
  text-decoration: none !important;
}
.btn-link {
  color: var(--blue);
}
button {
  background: transparent;
  border: none;
  outline: 0 !important;
  box-shadow: none !important;
}
